@font-face {
  font-family: "CeliaGarcia";
  src: url("../public//font.ttf.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  background-color: #e0e0e0;
}

.container {
  display: flex;
  gap: 20px;
}

.header {
  float: left;
  margin-top: 45px;
  position: relative;
  margin-left: 25px;
  margin-bottom: 60px;
  font-family: CeliaGarcia;
}

.info {
  width: 110px;
  height: 60px;
  border-radius: 5px;
  background-color: rgba(158, 170, 255, 0.37);
  float: left;
  margin-right: 20px;
}

.text {
  display: block;
  text-align: center;
  padding-top: 7px;
  font-weight: bold;
  font-size: 16pt;
}

.num {
  display: block;
  text-align: center;
  font-size: 15pt;
  font-family: CeliaGarcia;
}

#start_pause_button {
  position: absolute;
  top: 71px;
  left: 5px;
  width: 100px;
  height: 40px;
  background-color: rgba(18, 73, 7, 0.17);
  border-radius: 5px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 15pt;
  border: none;
  font-family: CeliaGarcia;
}

#reset_button {
  position: absolute;
  top: 71px;
  left: 135px;
  width: 100px;
  height: 40px;
  background-color: rgba(18, 73, 7, 0.17);
  border-radius: 5px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 15pt;
  border: none;
  font-family: CeliaGarcia;
}

#shuffle_button {
  position: absolute;
  top: 71px;
  left: 245px;
  width: 100px;
  height: 40px;
  background-color: rgba(18, 73, 7, 0.17);
  border-radius: 5px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 15pt;
  border: none;
  font-family: CeliaGarcia;
}

#help_button {
  margin-top: 20px;
  width: 100px;
  height: 40px;
  background-color: rgba(18, 73, 7, 0.17);
  border-radius: 5px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 15pt;
  border: none;
  font-family: CeliaGarcia;
}

.timepoint {
  width: 110px;
  height: 60px;
  border-radius: 5px;
  background-color: rgba(158, 170, 255, 0.37);
  float: left;
  margin-right: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.game_heading {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 40pt;
  float: right;
  padding-right: 10px;
  text-align: center;
  font-family: CeliaGarcia;
}

.board {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  grid-template-rows: repeat(4, 100px);
  gap: 15px;
  background-color: #8fc0a9;
  padding: 25px;
  border-radius: 10px;
}

.tile.empty {
  background-color: #d3e0dc;
  cursor: default;
}

.board {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  grid-template-rows: repeat(4, 100px);
  gap: 15px;
  background-color: #8fc0a9;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  transition: filter 0.3s ease;
}

.board.paused {
  filter: blur(5px);
}

.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff799;
  font-size: 35px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-family: CeliaGarcia;
}

.tile.empty {
  background-color: #d3e0dc;
  cursor: default;
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 32%;
  background-color: rgba(0, 0, 0, 0.66);
  padding: 20px 40px;
  width: 420px;
  height: 460px;
  border-radius: 10px;
  font-size: 68px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
  font-family: CeliaGarcia;
}
